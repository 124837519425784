import React, {useEffect, useState} from "react";
import { Dimensions } from "react-native";

export default function isMobile (){
    const [isMobile, setMobile] = useState(Dimensions.get("window").width > 800? false: true);
    
    useEffect(() => { 
        if(Dimensions.get("window").width > 800){
            setMobile(false);
        }else{
            setMobile(true);
        }
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                if(window.width > 800){
                    setMobile(false);
                }else{
                    setMobile(true);
                }
            }
          );
          return () => subscription?.remove();
    });
    return isMobile;
}