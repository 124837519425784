import React, {useState} from "react";
import {Text, View, StyleSheet, TouchableOpacity, Modal, TouchableWithoutFeedback, Image} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import firebaseApi from "../modules/firebaseApi";
import globalStyles from "../modules/globalStyles";
import FlatButton from "./FlatButton";
export default function DiscountView(){
    const [discounts, setDiscounts] = useState();
    const [showDeal, setShowDeal] = useState(null);
    const [dealImage, setDealImage] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    
    if(discounts == null){
        firebaseApi.getDiscounts(setDiscounts);
        firebaseApi.getUserInfo(setUserInfo);
    }
    function redeemDeal(){
        if(showDeal){
            var tempUserInfo = {...userInfo};
            if(!tempUserInfo){
                tempUserInfo = {};
            }
            if(!tempUserInfo.redeemedDeals){ 
                tempUserInfo.redeemedDeals = [];
            }
            tempUserInfo.redeemedDeals.push(showDeal.id);
            firebaseApi.saveUserInfo(tempUserInfo);
            
            setShowDeal({...showDeal, type:"dealReedemed"})
        }
    }
    function checkForRedeemed(dealId){
        return (userInfo && userInfo.redeemedDeals && userInfo.redeemedDeals.includes(dealId))
    }

    //Modal Pop Ups

    //Can make more complex deal popups with deal.type
    const simpleDealView = (
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={styles.simpleDealTitle} >{(showDeal && showDeal.placeName)? showDeal.placeName : undefined }</Text>
            {(dealImage)? <Image style={styles.dealImage} source={{uri: dealImage}}/> :undefined }
            <Text style={styles.simpleDealName}>{(showDeal && showDeal.name)? showDeal.name : undefined }</Text>
            <Text>Please show deal before deal before reedeming</Text>
            <Text >Id:{(showDeal && showDeal.id)? showDeal.id : undefined }</Text>
            <FlatButton text={"Redeem"} style={{backgroundColor:"lightgray", alignSelf:"center"}} onPress={()=>redeemDeal()}/>
            <FlatButton text={"Cancel"} style={{backgroundColor:"lightgray", alignSelf:"center"}} onPress={()=>setShowDeal(null)}/>
        </ScrollView>
    )
    const dealReedemedView = (
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={styles.simpleDealTitle} >{(showDeal && showDeal.placeName)? showDeal.placeName : undefined }</Text>
            <Text style={styles.simpleDealTitle}>Deal Redeemed!</Text>
            <Text style={styles.simpleDealName}>{(showDeal && showDeal.name)? showDeal.name : undefined }</Text>
            <Text >Id:{(showDeal && showDeal.id)? showDeal.id : undefined }</Text>
            <FlatButton text={"Close"} style={{backgroundColor:"lightgray", alignSelf:"center"}} onPress={()=>setShowDeal(null)}/>
        </ScrollView>
    )
    return(
        <>
            <Modal animationType="slide"
                transparent={true}
                visible={showDeal == null? false: true}>
                <TouchableWithoutFeedback onPress={()=> setShowDeal(null)}>
                    <View style={styles.modalBgView}>
                        <View style={styles.modalView}>

                            {(() =>{
                                if(showDeal && showDeal.type === "dealReedemed" ){
                                    return dealReedemedView;
                                }else if(showDeal && showDeal.name ){
                                    return simpleDealView;
                                } 

                            })()}
                            


                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        {(discounts == null)? 
            <>
                <Text style={globalStyles.pageSecondaryTitle}>Loading...</Text>
            </>
            :
            Object.entries(discounts).map(function(discount, i){
                var id = discount[0];
                var deals = discount[1].deals;
                var name = discount[1].name;
                return(
                    <View>
                        <Text style={[globalStyles.pageSecondaryTitle, {fontWeight:"normal"}]}>{name}</Text>
                        {
                            Object.entries(deals).map(function(deal, i){
                                const currentDeal = deal[1];
                                currentDeal.placeName = name;
                                firebaseApi.getFirebaseImage("/discountAssets/"+id+"/"+currentDeal.id+".jpeg", setDealImage)
                                return(
                                    <TouchableOpacity style={styles.discountView} disabled={checkForRedeemed(currentDeal.id)} onPress={() =>{
                                        setShowDeal(currentDeal);
                                    }}>
                                        <Text style={[(checkForRedeemed(currentDeal.id))? {textDecorationLine: 'line-through'} :undefined]}>{currentDeal.name}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </View>
                )
            })
            
        }
        </>
    )

}
const styles = StyleSheet.create({
    discountView:{
        backgroundColor:"white",
        borderRadius: 25,
        padding: 10,
        justifyContent: "center",
        textAlign:"center"
    },
    modalBgView:{
        
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        width: "100%",
        height:"100%",
        alignSelf:"center",
        justifyContent: 'center',
    },
    modalView:{
        borderRadius: "25px",
        backgroundColor: "white",
        width:"80%",
        height:"80%",
        alignSelf: "center",
        justifyContent:"space-between",
        textAlign: "center",
    },
    dealImage:{
        width: 200, 
        height: 200, 
        alignSelf:"center",
        marginBottom: 20,
    },
    simpleDealTitle:{
        flex:1,
        marginVertical: 20,
        fontSize:30,
    },
    simpleDealName:{
        flex:1,
        fontSize:20,
    }

})