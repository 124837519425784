import {StyleSheet} from "react-native";

const globalStyles = StyleSheet.create({
    background:{
        backgroundColor:"#00d1b2",
        flex:1,
        alignItems:"center",
        justifyContent:"flex-start", 
    },
    pageTitle:{
        textAlign: 'center',
        color:"#fff",
        fontWeight:"bold",
        fontSize: 50,
        paddingTop: 50,
        
    },
    pageSubtitle:{
        textAlign: 'center',
        color:"#fff",
        fontSize: 30,
        marginBottom: 50,
    },
    pageSecondaryTitle:{
        textAlign: 'center',
        color:"#fff",
        fontSize: 25,
        fontWeight:"bold",
        padding: 20,
    },
    pageLines:{
        marginVertical: 10,
        backgroundColor: '#FFF',
        height: 2,
        width: "80%"
    },
    pageLayeredButton:{
        backgroundColor:"#d3d3d3", 
        flexDirection:"row", 
        padding: 5,
        alignSelf: 'center',
        display: "flex",
        borderRadius: 5,
        margin: 5,
    },
    link:{
        padding: 5,
        fontSize:15,
        justifyContent:"flex-start",
        color:"blue",
        textDecorationLine: 'underline'
    },
    linkBackground:{
        marginTop: 10,
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"row",
    },
    multlineLinkBox:{
        flexDirection:"row", 
        padding: 10, 
        flexWrap:"wrap", 
        justifyContent:"center"
    }
});

export default globalStyles;