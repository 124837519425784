import React from "react";
import globalStyles from "../modules/globalStyles";
import { LinearGradient } from 'expo-linear-gradient';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'


export default function Background (props){
    return( <LinearGradient
        // Background Linear Gradient
        //Other color #00e7eb
        start={[0,0]}
        end={[1,0]}
        colors={['#009e6c' ,'#00d1b2']}
        style={globalStyles.background}
        >
            
                {props.children}
            
        </LinearGradient>
    
    )

}