import Firebase from "../modules/firebaseApi";
const TAGS = [
    "local",
    "dine-in",
    "take-out",
    "brewery",
    "dinner",
    "lunch",
    "breakfast",
    "indoor-seating",
    "outdoor-seating",
    "vegan",
    "vegetarian",
    "pets-allowed",
    
];
var currentQuery
/**
 * @param query: String
 * @returns Array<Listing>
 */

function query(query) {
    if (query === "") {
        return Firebase.getListings();
    }
    
    Firebase.logEvent("search", {
        search_term: query,
    })
    currentQuery = query;
    const[tags, title] = parseTags(query.toLowerCase());
    return Firebase.getListings().filter(l => {
        if(title){
            return Object.entries(tags).every(([name, include]) => {
            
                return include ? l.tags[name] !== undefined && l.tags[name] !== false : l.tags[name] === false;
            }) && l.name.includes(title);
        }else{
            return Object.entries(tags).every(([name, include]) => {
                return include ? l.tags[name] === true : l.tags[name] === false;
            }) ;
        }
       
    });
}

function getCurrentQuery(){
    return currentQuery;
}

/**
 * @param query
 * @returns Object {
 *     [tag: String]: Boolean include
 * }
 */
function parseTags(query) {
    let tags = {};
    let tag = "";
    let include = true;
    let title = "";
    let errors = [];
    query.split(" ").forEach(s => {
        if (s === "no") {
            include = false;
        } else {
            tag += tag === "" ? s : "-" + s;
            if (TAGS.includes(tag)) {
                if (tags[tag] === undefined) {
                    tags[tag] = include;
                }
                title = "";
                tag = ""; 
                include = true;
            }else{
                title = tag;
            }
        }
    });
    if (tag !== "") {
        if (TAGS.includes(tag)) {
            if (tags[tag] === undefined) {
                tags[tag] = include;
                title = "";
            }
        }
    }
    if (errors.length !== 0) {
        alert(errors.join("\n"));
    }
    
    return [tags, title];
}
export default {
    TAGS,
    query,
    getCurrentQuery,
}