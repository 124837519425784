import React from "react";
import {View, StyleSheet, Text, TouchableOpacity} from "react-native";
import globalFunction from "../modules/globalFunctions";
import TagView from "./TagView"
import Heart from "./Heart";
import isMobile from "../modules/isMobile";
export default function listingCard({title, hours, tags, id, onPress}){
    
    
    return(
        <TouchableOpacity style={[styles.card, (isMobile()? {marginHorizontal: 40}: {width: "30%"})]} onPress={onPress}>
            <View>
                {/*<Heart style={{alignSelf:"flex-end"}} id={id} didUpdate={() =>{
                    
                }} /> */}
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.hours}>{hours}</Text>
                <View style={styles.line}></View>
                <View style={styles.tagContainer}>
                    <TagView tags={tags} />
                </View>
            </View>
        </TouchableOpacity>
    )
}



const styles = StyleSheet.create({
    card:{
        backgroundColor:"white",
        padding: 16,
        marginVertical: 12,
        borderRadius: 15,

    },
    title:{
        fontSize: 30,
        textAlign: "center",

    },
    hours:{        
        textAlign: "center",
        marginTop: 10,
        fontSize: 15,
        
    },
    line:{
        marginTop: 5,
        borderBottomWidth: .5,
        borderBottomColor: "#D3D3D3",
    },
    tagContainer:{
        marginTop: 10,
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        justifyContent: "center",
    }
})