import React from "react";
import {View, Text} from "react-native"
import search from "./search";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function displayArray(arrayOfString, style){
  return arrayOfString.map(e => {
      if(typeof e === "string"){
        return(
          <Text style={style}>{capitalizeFirstLetter(e)}</Text>
        )
      }else{
        return(
          <Text style={style}>{e.open+"-"+e.close}</Text>
        )
      }
     
  })
}
function getTags(){
  var tags = [...search.TAGS];
  for(var i=0; i < tags.length; i++) {
    tags[i] = tags[i].replace('_', ' ');
  }
  return tags
}
export default {
    displayArray,
    getTags,
    capitalizeFirstLetter
};