import React from "react";
import {StyleSheet, View, Text, TouchableOpacity, TextInput, Platform, ScrollView} from "react-native";
import globalStyles from "../modules/globalStyles";
import FlatButton from "../components/FlatButton";
import { LinearGradient } from 'expo-linear-gradient';
import Background from "../components/Background";

export default function Home ({navigation}){
    function searchListing(searchTerm) {
        navigation.navigate("Listings");
        window.searchListing(searchTerm);
    }
    return(
        <Background>
            <ScrollView contentContainerStyle={{alignItems: "center", width:"100vw" , height:"100%"}}>
                <Text style={globalStyles.pageTitle}>AtLocal</Text>
                <Text style={[globalStyles.pageSubtitle, {marginBottom:10}]}>Search businesses in the Gainesville area</Text>
            
                <View style={globalStyles.pageLines} />
                <Text style={globalStyles.pageSecondaryTitle}>I'm looking for restaurants that...</Text>
                <FlatButton text="Are local to Gainesville" onPress={()=>{
                    navigation.navigate("Listings", {query: "local"});
                    if (Platform.OS == "web") {
                       // window.location.href = '/listings?query=local';
                    }else{
                        
                    }
                }}/>
                <FlatButton text="Offer vegetarian options" onPress={()=>{
                    navigation.navigate("Listings", {query: "vegetarian"});
                    if (Platform.OS == "web") {
                        //window.location.href ='/listings?query=vegetarian';
                    }else{
                        
                    }
                }}/>
                <FlatButton text="Have outdoor seating" onPress={()=>{
                    navigation.navigate("Listings", {query: "outdoor-seating"});
                    if (Platform.OS == "web") {
                        //window.location.href = '/listings?query=outdoor-seating';
                    }else{
                    }
                }}/>
                <FlatButton text="Allow pets" onPress={()=>{
                    navigation.navigate("Listings", {query: "pets-allowed"});
                    if (Platform.OS == "web") {
                        //window.location.href ='/listings?query=pets-allowed';
                    }else{
                        
                    }
                }}/>
                <View style={[globalStyles.pageLines, {marginVertical: 30}]} />
            </ScrollView>
        </Background>
        
        
        
    )
}

const styles = StyleSheet.create({
    
    
})