import React, {useState, useEffect} from "react"
import {View, StyleSheet, FlatList, Platform, Text} from "react-native";
import globalStyles from "../modules/globalStyles";
import firebase from "../modules/firebaseApi";
import ListingCard from "../components/ListingCard";
import Search from "../modules/search";
import Background from "../components/Background";
import isMobile from "../modules/isMobile";
import Header from "../components/Header";
import { useIsFocused } from '@react-navigation/native'
import {useRoute} from '@react-navigation/native';
    
 /**
 * @param listing: Listing
 * @param date_ymd: String yyyy-mm-dd
 * @param date_weekday: String monday | ... | sunday
 * @param date_display: String
 * @param default_label: String | undefined
 * @returns String
 */
function getHours(listing) {
    const date = new Date();
    
    const date_ymd = [
        date.getFullYear(),
        date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
    ].join("-");
    const date_weekday = date.toLocaleDateString("en-US", {weekday: "long"}).toLowerCase();
    const date_display = date.toLocaleDateString("en-US", {weekday: "long", month: "numeric", day: "numeric"});
                        
    const result = listing.hours[date_ymd] ?? listing.hours[date_weekday] ?? listing.hours.daily;
    const hours = typeof result === "string" ? result : result.value ?? result.open + " - " + result.close;
    const label = result.label ?? "Today" ?? date_display;
    return label + ": " + hours;
}

var listingForSearch = "";
var updateSearchResults = null;



export default function Listings({navigation}){

    const route = useRoute();
    const [listings,setListings] = useState("unloaded");

    /**
     * @param searchText: String
    */
    window.searchListing = (searchText) =>{
        
        if (updateSearchResults == null){
            listingForSearch = searchText;
        }else{
            updateSearchResults(searchText)
        }
    }

    if(Platform.OS === "web" && window.location.search){
        listingForSearch = decodeURI(window.location.search.split("=")[1]);
    }
    if (route.params && route.params.query) {
        
        listingForSearch = route.params.query;
        window.location.search.replace("query="+listingForSearch);
    }
    
    
    /**
    * @param searchText: String
    */
    updateSearchResults = (searchText) =>{
        firebase.setListings(setListings, searchText);   
    }

    if (listings === "unloaded") {
        setListings("loading")
        updateSearchResults(listingForSearch);
    }
    
    
    function logSelectEvent(listingId){
        firebase.logEvent("select_listing", {
            query: Search.getCurrentQuery(),
            listing: listingId,
            
        })
    }

    
    return(
        <Background>
                <View style={styles.listing}>
                    {(()=>{
                        if(isMobile()){
                            if(listings === "unloaded" || listings === "loading" ){
                                return(<>
                                    <Text style={globalStyles.pageTitle} >Loading...</Text>
                                </>)
                            }
                            return(
                                <FlatList 
                                data={listings}
                                key={'_'}
                                contentContainerStyle={{flexGrow: 1, alignItems: 'center'}}
                                
                                nestedScrollEnabled={true}
                                renderItem={({item})=>(
                                    <ListingCard title={item.name} hours={getHours(item)} id={item.id} tags={item.tags} onPress={() => {
                                        logSelectEvent(item.id);
                                        navigation.navigate("Listing",{id:item.id});
                                    }}  />
                                )}
                                />
                            )
                        }else{

                            if(listings === "unloaded" || listings === "loading" ){
                                return(<>
                                <Text style={globalStyles.pageTitle} >Loading...</Text>
                                </>)
                            }
                            
                            return(
                                <FlatList 
                                key={'#'}
                                keyExtractor={item => "#" + item.id}
                                data={listings}
                                numColumns={3}
                                columnWrapperStyle={{  flex: 1,justifyContent: "space-evenly"}}
                                nestedScrollEnabled={true}
                                renderItem={({item})=>(
                                <ListingCard title={item.name} hours={getHours(item)} id={item.id} tags={item.tags} onPress={() => {
                                    logSelectEvent(item.id);
                                    navigation.navigate("Listing",{id:item.id});
                                }}  />
                        )}
                        />  
                            )
                        }
                    })()}    
                </View> 
        </Background>
    
    )
}

const styles = StyleSheet.create({
    listingBackground:{
        flex: 1,
        backgroundColor:"#00d1b2",
    },
    listing:{
        flex: 1,
        width:"100vw",
    }
    
})