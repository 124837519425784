import React from "react";
import {View, Text, Linking, ScrollView, Platform} from "react-native";
import globalStyles from "../modules/globalStyles";
import FlatButton from "../components/FlatButton";
import Background from "../components/Background";
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
export default function About(){
    return(
        <Background>
            <ScrollView contentContainerStyle={{alignItems:"center", width:"100vw", height:"100%"}}>
                <Text style={[globalStyles.pageTitle, {paddingBottom: 30} ]}>About AtLocal</Text>
                <Text style={{color:"#fff",fontSize:18,paddingHorizontal:30, textAlign:"center"}}>We're a Gainesville startup that helps people discover local restaurants through detailed, accurate information. The information we provide is near impossible to search for with traditional search engines (vegetarian/vegan, outdoor seating, pets allowed, etc.) and often falls out of date or is simply inaccurate. Instead, we partner with the business directly to ensure everything on our website is as accurate as possible!</Text>

                
                <View style={globalStyles.pageLines} />
                <Text style={[globalStyles.pageSubtitle, {marginBottom: 20}]}>Connect with us on Discord!</Text>
                <FlatButton text="Discord" icon={(<MaterialCommunityIcons name="discord" size={30} color="black" />)} onPress={()=>{
                    Linking.openURL('https://discord.com/invite/rTFAWdyWgx')
                }}/>
                
                {Platform.OS === "web"?
                <View style={globalStyles.multlineLinkBox}>
                    <Text style={{fontSize:25, color:'white'}}>Email us:</Text>
                    <Text style={[globalStyles.link, {fontSize:25, padding:0}]}
                            onPress={() => Linking.openURL('mailto:atlocalapp@gmail.com')}>
                        atlocalapp@gmail.com
                    </Text>
                </View>
                :
                <>
                <Text style={[globalStyles.pageSubtitle, {marginVertical: 20}]}>Click to email us:</Text>
                <FlatButton text="atlocalapp@gmail.com" onPress={()=>{
                    Linking.openURL('mailto:atlocalapp@gmail.com')
                }}/> 
                </>
                }
                <View style={[globalStyles.pageLines, {marginBottom: 20}]} />
            </ScrollView>
        </Background>
    )
}