import React, {useEffect, useState} from 'react';
import { initializeApp, getApp } from 'firebase/app';
import {initializeFirestore, enableIndexedDbPersistence, getDocs, doc, collection, onSnapshot, getDoc, setDoc} from 'firebase/firestore';
import { getAnalytics, logEvent as LogEvent  } from "firebase/analytics";
import {getAuth,GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import search from './search';
initializeApp({
    apiKey: "AIzaSyCqDXKQbW1Kv0ZwKzIqm514L4jlyxkLL4s",
    authDomain: "atlocal-app.firebaseapp.com",
    projectId: "atlocal-app",
    storageBucket: "atlocal-app.appspot.com",
    messagingSenderId: "844585085629",
    appId: "1:844585085629:web:3a8c4dc0ad996a122da5d4",
    measurementId: "G-TSYW8QCY01"
});

const database = initializeFirestore(getApp(), {
    experimentalForceLongPolling: true,
 });
enableIndexedDbPersistence(database)
  .catch((err) => {
      
  });
const auth = getAuth();
const storage = getStorage();
const analytics = getAnalytics();

const googleProvider = new GoogleAuthProvider();

let listings = {};
let discounts = {};
let userInfo = {};
let stateSetUserInfo;


function listingsLoaded(){
    if(listings != null && Object.values(listings).length != 0){
        return true;
    }
    return false;
}
function discountsLoaded(){
    if(discounts != null && Object.values(discounts).length != 0){
        return true;
    }
    return false;
}
function userInfoLoaded(){
    if(userInfo != null && Object.values(userInfo).length != 0){
        return true;
    }
    return false;
}
async function getCurrentUserInfo(){
    const user = auth.currentUser;
    if(user){
        const results = await getDoc(doc(database, "users", user.uid));
        userInfo = results.data();
    }
}

async function init() {
    //get listings
    if(listingsLoaded() == false){
        const results = await getDocs(collection(database, "regions", "gainesville", "listings"));
        results.forEach(d => listings[d.id] = {...d.data(), id: d.id});
    }
    onSnapshot(collection(database, "regions", "gainesville", "listings"), (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            let d = doc.data();
            listings[doc.id] = {...d, id: doc.id};
        });
    });
    //get discounts
    if(discountsLoaded() == false){
        const resultOfDiscount = await getDocs(collection(database, "regions", "gainesville", "discounts"));
        resultOfDiscount.forEach(d => discounts[d.id] = {...d.data(), id: d.id});
    }
    onSnapshot(collection(database, "regions", "gainesville", "discounts"), (querySnapshot) => {
        querySnapshot.forEach((doc) => {                
            let d = doc.data();
            discounts[doc.id] = {...d, id: doc.id};
        });
    });

    //get userInfo
    auth.onAuthStateChanged(user => {
        if(user){
            if(userInfoLoaded() == false){
                getCurrentUserInfo();
            }
            onSnapshot(doc(database, "users", user.uid), (doc) => {             
                userInfo = doc.data();
                if(stateSetUserInfo){
                    stateSetUserInfo(userInfo);
                }
            });
            
        }
    });
    
}


/**
 * @param setListings: setState
 * @param listingForSearch: String
 */
async function setListings(setListings, listingForSearch) {
    if(listingsLoaded() == false){
        const results = await getDocs(collection(database, "regions", "gainesville", "listings"));
        results.forEach(d =>{ 
            if(d.id){
                listings[d.id] = {...d.data(), id: d.id}}
            }
        );
    }else{
        //this is used to make sure header is loaded first
        await new Promise(resolve => setTimeout(resolve, 100));
    }
    
    if(setListings){
        global.setTextSearch(listingForSearch);
        setListings(search.query(listingForSearch));
        
    }
}

/**
 * @returns Array<Listing>
 */
function getListings() {
    return Object.values(listings);
}

/**
 * @param lisitingId: String
 */
async function getListing(lisitingId) {
    var result;
    if(listingsLoaded() == false){ 
        const results = await getDoc(doc(database, "regions", "gainesville", "listings", lisitingId));
        result = results.data();
    }else{
        result = listings[lisitingId];
    }
    
    return result;
}

/**
 * @param event: String
 * @param state: Object<Any>
 */
function logEvent(event, state) {
    LogEvent(analytics, event, state )
}

/**
 * @param info: Object<Any>
*/
function saveUserInfo(info) {
    const user = auth.currentUser;
    if(user){
        setDoc(doc(database, "users", user.uid), info)
    }
    
}
/**
 * @param setUserInfo: setState
*/
async function getUserInfo(setUserInfo) {
    const user = auth.currentUser;
    if(user && userInfoLoaded() == false){
        const results = await getDoc(doc(database, "users", user.uid));
        userInfo = results.data();
    }
    stateSetUserInfo = setUserInfo;
    setUserInfo(userInfo);
    return;
}

/**
 * @param setDiscounts: setState
 */
async function getDiscounts(setDiscounts){
    if(discountsLoaded() == false){ 
        const resultOfDiscount = await getDocs(collection(database, "regions", "gainesville", "discounts"));
        resultOfDiscount.forEach(d => discounts[d.id] = {...d.data(), id: d.id});
    }
    setDiscounts(discounts);
    return;
}

async function getFirebaseImage(path, setImage){
    getDownloadURL(ref(storage,path))
    .then((url) => {
        setImage(url);
    })
    .catch((error) => {
        setImage(null)
    });
    
}

function loginWithGoogle() {
    signInWithPopup(auth ,googleProvider);
}

function isSignedIn(){
    const [isLoggedIn, setLoggedIn] = useState((auth.currentUser)? true : false);
    
    auth.onAuthStateChanged(user => {
        if(user){
            setLoggedIn(true);
        }else{
            setLoggedIn(false);
        }
    });
    return isLoggedIn;
}

export default {
    init,
    getListings,
    logEvent,
    getListing,
    setListings,
    loginWithGoogle,
    saveUserInfo,
    getUserInfo,
    getDiscounts,
    getFirebaseImage,
    isSignedIn,
}
