import React, {useState} from "react";
import {View, Text, ScrollView, StyleSheet, Dimensions, Platform, TouchableOpacity, Linking} from "react-native";
import globalStyles from "../modules/globalStyles";
import AppLoading from 'expo-app-loading';
import globalFunctions from "../modules/globalFunctions";
import Background from "../components/Background";
import {MaterialIcons} from "@expo/vector-icons"
import globalFunction from "../modules/globalFunctions";
import firebase from "../modules/firebaseApi";
import {useRoute} from '@react-navigation/native';
import TagView from "../components/TagView";
import isMobile from "../modules/isMobile";
export default function Listing({ navigation }) {
    const route = useRoute();
    const [listing,setListing] = useState();
    const [didGetData, setSetData] = useState(false);
    var firebaseResults;
    const tagBoxStyle = {
        padding:5,
        margin: 5,
        borderRadius: 5,
        textAlign: "center",
        justifyContent: "center",
    }
    const MapApiKey = "AIzaSyCAO9YbSmSS6oQdmePtdv0X33Y6kw13ems";
    /**
    * @param address: String
    */
    function generateWebView(address){
        var MapView;
        if(Platform.OS != "web"){
            //MapView = require('react-native-maps');
        }
          return(
            <View>
              {(Platform.OS === "web") ? 
                <iframe src={"https://www.google.com/maps/embed/v1/place?key="+MapApiKey+"&q="+address} height={200} width={Dimensions.get('window').width} style={{width: "100%"}} /> 
                :
                /*<MapView
                    style={{height:200, width: "100vw"}}
                    initialRegion={{
                        latitude: 37.78825,
                        longitude: -122.4324,
                        latitudeDelta: 0.04,
                        longitudeDelta: 0.05,
                    }}
                />*/ undefined}
            </View>
        )
    }
    /**
    * @param website: String
    */
    function cleanWebsite(website){
        website = website.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, "");
        if(website.includes("www.")){
            website = website.replace("www.", "");
        }
        if(website.includes("/")){
            website = website.split("/")[0];
        }
        return website;
    }
    async function getData(){ 
        
        firebaseResults = await firebase.getListing(route.params.id);
        return;
    }
 
    return(
        <Background>
            {listing && listing.name? 
            <ScrollView contentContainerStyle={{alignItems: "center", width:"100vw", height:"100%"}}>
                <Text style={[globalStyles.pageTitle, { paddingTop: 10, justifyContent:"flex-start"}]}>{listing.name}</Text>
                <TouchableOpacity onPress={() => {
                    var mapLink = 'https://www.google.com/maps/search/?api=1&query='+listing.address;
                    if(Platform.OS==="ios"){
                        mapLink = "maps://?daddr="+listing.address;
                    }
                    Linking.openURL(mapLink);
                }}>
                    <Text style={[globalStyles.pageSubtitle, {paddingTop: 10,justifyContent:"flex-start", fontSize:20, textDecoration:"underline"}]}>{listing.address}</Text>
                </TouchableOpacity>
                <View style={{paddingBottom:10}}>
                    {generateWebView(encodeURI(listing.address))}
                </View>
                {/* Listing Data */}
                <View style={styles.listingBackground}>
                    <View style={[{flexDirection:"row", justifyContent:"space-evenly", flexWrap: 'wrap'}]}>
                        {listing.email===""? undefined:
                            <TouchableOpacity onPress={() => Linking.openURL('tel:'+listing.phone)}>
                                <View style={globalStyles.pageLayeredButton}>
                                    <MaterialIcons name={"email"} size={20} style={{paddingTop: 4}}/>
                                    <Text style={[globalStyles.pageSecondaryTitle, globalStyles.link]}>{listing.email}</Text>
                                </View>
                            </TouchableOpacity>
                        }
                        
                        <TouchableOpacity onPress={() => Linking.openURL('tel:'+listing.phone)}>
                                <View style={globalStyles.pageLayeredButton}>
                                    <MaterialIcons name={"phone"} size={20} style={{paddingTop: 4}}/>
                                    <Text style={[globalStyles.pageSecondaryTitle, globalStyles.link]}>{listing.phone}</Text>
                                </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => Linking.openURL(listing.website)}>
                            <View style={globalStyles.pageLayeredButton}>
                                <MaterialIcons name={"web"} size={20} style={{paddingTop: 4}}/>
                                <Text style={[globalStyles.pageSecondaryTitle, globalStyles.link]}>{cleanWebsite(listing.website)}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    
                    <View style={styles.hoursContainer}>
                        <View style={{width:100}}>
                            <Text style={[globalStyles.pageSecondaryTitle, {color:"black"}]}>Day</Text>
                            {globalFunctions.displayArray(Object.keys(listing.hours),{color:"black", fontSize: 20, textAlign:"center", borderBottomWidth: 3, borderColor: "black", width:100 })}
                        </View>
                        <View style={{width:250}}>
                            <Text style={[globalStyles.pageSecondaryTitle, {color:"black"}]}>Hours</Text>
                            {globalFunctions.displayArray(Object.values(listing.hours),{color:"black", fontSize: 20 ,  textAlign:"center", borderBottomWidth: 3, borderColor: "black",  width:250})}
                        </View>
                    </View>
                    <Text style={[globalStyles.pageSecondaryTitle, {paddingTop:50, color:"black", paddingBottom: 5}]}>Features:</Text>
                    <View style={styles.tagContainer}>
                        <TagView tags={listing.tags} />
                    </View>
                </View>
            </ScrollView>
            :
            <>
                <AppLoading
                startAsync={getData}
                onError={console.warn}
                onFinish={()=>{
                    setListing(firebaseResults);
                }}
                />
                <Text style={globalStyles.pageTitle} >Loading...</Text>
            </>
        }
        </Background>
    )
}

const styles = StyleSheet.create({
    
    listingBackground:{
        backgroundColor:"white", 
        marginHorizontal: 100, 
        marginVertical: 30,
        borderRadius:20, 
        padding: 10,
        flexWrap: 'wrap',
    },
    hoursBackground:{
        paddingTop: 20
    },
    hoursContainer:{
        flexDirection:"row", 
        marginHorizontal: 10, 
        backgroundColor: "white", 
        borderRadius: 5, 
        paddingBottom: 20, 
        justifyContent:"center",
        alignItems: "center",
    },
    tagContainer:{
        borderRadius: 5,
        backgroundColor:"#FFF",
        marginBottom: 10,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginHorizontal: 30,
        paddingBottom: 10,
        alignItems:"center",
        justifyContent: "center",
    }


})
