import React, {useState} from "react";
import {StyleSheet, View, Text, TouchableOpacity, Image} from "react-native";
import globalStyles from "../modules/globalStyles";
import {onAuthStateChanged, getAuth} from 'firebase/auth';
import firebaseApi from "../modules/firebaseApi";
import Background from "../components/Background";
import FlatButton from "../components/FlatButton";
import DiscountView from "../components/DiscountView";
export default function Account ({navigation}){
    const auth = getAuth();
    const [user, setUser] = useState("not ready");
    onAuthStateChanged( auth , (user) => {
        setUser(user);
    });
    return(
        <Background>
            {(()=>{
                if(user === "not ready"){
                    return (
                        <Text style={globalStyles.pageTitle}>Loading...</Text>
                    )
                }else if(user){
                    return(
                        <>
                            <Text style={[globalStyles.pageTitle, {paddingBottom: 50} ]}>{(user.displayName)? "Welcome "+user.displayName: "Signed In"}</Text>
                            <View style={globalStyles.pageLines} />
                            <Text style={[globalStyles.pageSubtitle, {marginBottom: 10}]}>Discounts: </Text>
                            <DiscountView  />
                            <View style={globalStyles.pageLines} />
                            <FlatButton text={"Sign Out"} onPress={()=>{
                                auth.signOut();
                            }} />
                        </>
                    )
                }else{
                    return(
                        <>
                            <Text style={globalStyles.pageTitle}>Account</Text>
                            <TouchableOpacity onPress={firebaseApi.loginWithGoogle} style={{paddingTop:50}} >
                                <Image source={require("../assets/googleSign.png")} style={{ width: 200, height: 50 }} />
                            </TouchableOpacity>
                        </>
                    )
                }
            })()}
        </Background>
    )
}

const styles = StyleSheet.create({
    
    
})