import React, {useState, useLayoutEffect, useRef} from "react";
import {View, Text, TouchableOpacity, Dimensions, StyleSheet, TextInput, ScrollView, FlatList, Platform} from "react-native";
import {MaterialIcons, MaterialCommunityIcons} from "@expo/vector-icons"
import search from "../modules/search";
import globalFunctions from "../modules/globalFunctions";
import globalStyles from "../modules/globalStyles";
import isMobile from "../modules/isMobile";
import {useRoute, DrawerActions} from '@react-navigation/native';
var isSearchFocused = false;
var shouldSearchStayFocused = false;
var searchInput;
export default function Header({navigation, title}){
    const route = useRoute();
    const [width, setWidth] = useState(Math.round(Dimensions.get("window").width));
    const [searchTerm, setSearchTerm] = useState("");
    const [showFeatures, setShowFeatures] = useState(false);
    const [suggestions, setSuggestions] = useState(globalFunctions.getTags());


    global.setTextSearch = (text) =>{

        setSearchTerm(text);
        window.location.search.replace("query="+text);

    }

    useLayoutEffect(() => {
        function updateSize(){
            setWidth(Math.round(Dimensions.get("window").width));
            setShowFeatures(false);
            searchInput.blur();
        }
        window.addEventListener('resize', updateSize);
    })
    const toggleDrawer = () => {
        navigation.toggleDrawer();
    }
    function checkSuggestion(){
        setSuggestions(globalFunctions.getTags());
        //ToDo Check for listings
    }
    const updateSearchResults = () => {
        checkSuggestion();
    }
    const addFeatureToSearch = (feature) => {
        if(searchInput.value == ""){
            setSearchTerm(feature);

        }else{
            setSearchTerm(searchInput.value+" "+feature);

        }
    }
    const searchListing = (event) => {
        if(route.name === "Listings"){
            setShowFeatures(false);
            window.searchListing(event.nativeEvent.text);
            navigation.setParams({
                query: event.nativeEvent.text,
              });
            setSearchTerm(event.nativeEvent.text);
        }else{
            navigation.navigate("Listings", {query: event.nativeEvent.text});
            if (Platform.OS == "web") {
                //window.location.href ='/listings?query='+event.nativeEvent.text;
            }else{

            }
        }

    }


    document.addEventListener('click', function(event) {
        if(isSearchFocused === false){
            if(shouldSearchStayFocused == true){
                shouldSearchStayFocused = false
            }else{
                setShowFeatures(false);
            }
        }

    });
    const searchBoxProp = (<TextInput
        ref={(ref) => { searchInput = ref; }}
        onSubmitEditing={searchListing}
        placeholder="Search tags.."
        onTextInput={updateSearchResults}
        onBlur={() => {
            //Workaround for handling focus
            setTimeout(function () {
                isSearchFocused= false;
                if(shouldSearchStayFocused == false){
                    setShowFeatures(false);
                }
            }, 200)
        }}
        onFocus={() => {
            isSearchFocused= true;
            setShowFeatures(true);
        }}
        style={width>700 ? styles.searchText : styles.searchTextMobile}
        value={searchTerm}
        onChangeText={(value) => {
            setSearchTerm(value);
        }}
        />)

    const featuresBox = (<View style={width>700 ? styles.suggestionBoxBackground : styles.suggestionBoxBackgroundMobile}>
        <Text style={{fontSize:20, paddingLeft:5, fontWeight:"bold"}}>Features</Text>
            <View style={[globalStyles.pageLines, {marginVertical:0, backgroundColor: '#4a4a4a', width: "100%"}]} />

        <FlatList
            data={suggestions}
            nestedScrollEnabled={true}
            renderItem={({item})=>(
                <TouchableOpacity onPress={() => {
                    shouldSearchStayFocused = true;
                    searchInput.focus();
                    addFeatureToSearch(item);
                }}>
                    <Text style={{fontSize:18, paddingLeft:10,color:"blue", paddingVertical:5 }}>{item}</Text>
                </TouchableOpacity>
            )}
            />

    </View>)
        return(
            <>
                {(isMobile() == false)?
                    <View style={ styles.navbar }>
                        <TouchableOpacity activeOpacity={0.7} style={styles.pageView} onPress={() => navigation.navigate("Home")} >
                            <Text style={styles.title}>{title}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={(route.name === "About")? styles.pageViewActive :styles.pageView} onPress={() => navigation.navigate("About")} >
                            <MaterialIcons size={30} style={styles.generalIcon} name="info" />
                            <Text style={styles.pageText}>About</Text>
                        </TouchableOpacity>

                        <TouchableOpacity activeOpacity={0.7} style={(route.name === "Listings")? styles.pageViewActive :styles.pageView} onPress={() => {
                            if(route.name === "Listings"){
                                window.searchListing("");
                                navigation.setParams({
                                    query: "",
                                });
                                setSearchTerm("");

                            }else{
                                navigation.navigate("Listings", {query: ""});
                            }

                        }}>
                            <MaterialIcons size={35} style={styles.generalIcon} name="store" />
                            <Text style={styles.pageText}>Listings</Text>
                        </TouchableOpacity>

                        <div style={{flexGrow: 1}}/>

                        <View style={styles.searchBox}>
                            <MaterialIcons size={25} style={styles.searchIcon} name="search" />
                            {searchBoxProp}
                        </View>

                        <TouchableOpacity activeOpacity={0.7} style={(route.name === "Account")? styles.pageViewActive :styles.pageView} onPress={() => navigation.navigate("Account")} >
                            <MaterialCommunityIcons size={35} style={styles.generalIcon} name="account" />
                            <Text style={styles.pageText}>Account</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={styles.navbarMobile}>
                        <TouchableOpacity activeOpacity={0.7} onPress={() => navigation.navigate("Home")} >
                            <Text style={[styles.title, {marginRight:0}]}>{title}</Text>
                        </TouchableOpacity>

                        <div style={{flexGrow: 1}}/>

                        <View style={styles.searchBoxMobile}>
                            <MaterialIcons size={25} style={styles.searchIconMobile} name="search" />
                            {searchBoxProp}
                        </View>
                        <TouchableOpacity activeOpacity={0.7} style={styles.pageView} onPress={() => toggleDrawer()}>
                            <MaterialIcons size={30} style={styles.menuIconMobile} name='menu'/>
                        </TouchableOpacity>
                    </View>
                }
                {/*showFeatures && featuresBox */}
            </>
        )


}

const styles = StyleSheet.create({
    title:{
        fontWeight: 'bold',
        fontSize: 25,
        color:"#4a4a4a",
        fontFamily:"BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif",
    },
    navbar:{
        width: "100vw",
        height: 60,
        flexDirection:'row',
        alignItems:'center',
        justifyContent: 'space-between',
        backgroundColor: "white",
    },
    navbarMobile:{
        width: "100vw",
        height: 60,
        flexDirection:'row',
        alignItems:'center',
        justifyContent: 'space-between',
        backgroundColor: "white",
    },
    pageView:{
        alignItems:"center",
        flexDirection: "row",
        paddingHorizontal: 15,
        height:"100%"
    },
    pageViewActive:{
        alignItems:"center",
        flexDirection: "row",
        backgroundColor:"lightgray",
        paddingHorizontal: 15,
        height:"100%"
    },
    pageText:{
        fontSize: 28,
        color:"#4a4a4a",
        fontFamily:"BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif",
    },
    generalIcon:{
        marginRight: 5,
        color:"#4a4a4a",
    },


    suggestionBoxBackground:{
        height: 200,
        backgroundColor: "white",
        width: "35vw",
        alignSelf: 'flex-end',
        position:"absolute",
        right: "-42vw",
        top: 50,
        borderRadius: 10,
        overflow: "hidden",
        borderWidth: "2px",
    },
    suggestionBoxBackgroundMobile:{
        height: 200,
        backgroundColor: "white",
        width: "50vw",
        alignSelf: 'flex-end',
        position:"absolute",
        left: 170,
        top: 50,
        borderRadius: 10,
        overflow: "hidden",
        borderWidth: "2px",
    },
    searchBox:{
        height: 42,
        justifyContent: "center",
        minWidth: 200,
        flexGrow: 0.7,
        margin:0,
        borderColor: "#4a4a4a",
        borderRadius: "30px",
        borderWidth: "1px",
        overflow: "hidden",
    },
    searchText:{
        flex: 1,
        alignItems: 'stretch',
        paddingLeft: 35,
        paddingRight: 15,
        borderRadius: "30px",
        fontSize: "1.1em",
    },
    searchIcon:{
        position: "absolute",
        bottom: 5,
        left: 5,
        color: "#4a4a4a",
    },
    searchBoxMobile:{
        height: 42,
        minWidth: 200,
        flexGrow: 0.7,
        borderColor: "#4a4a4a",
        borderRadius: 30,
        overflow: "hidden",
        borderWidth: "1px",
    },
    searchIconMobile:{
        position:"absolute",
        bottom:5,
        left: 5,
        color: "#4a4a4a",
    },
    searchTextMobile:{
        paddingLeft: 30,
        paddingRight: 10,
        borderRadius: 30,
        height: 50,
    },
    menuIconMobile: {
        color:"#4a4a4a",
    }

})