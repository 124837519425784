
import React, {useEffect} from "react";
import {createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem} from '@react-navigation/drawer';
import { Text, View} from "react-native";

import Home from "../pages/Home";
import Listings from "../pages/Listings";
import About from "../pages/About";
import Account from "../pages/Account";
import Header from "../components/Header";
import Listing from "../pages/Listing";
import Firebase from "../modules/firebaseApi";
import { NavigationContainer} from '@react-navigation/native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'

const Drawer = createDrawerNavigator();
const linking = {
  prefixes: ['https://beta.atlocal.app', "https://atlocal-app.web.app", "https://atlocal.app", 'atlocal://'],
  config: {
    screens: {
      Home: '',
      Listings: 'listings',
      About: 'about',
      Listing: {
        path: 'listings/:id',
      },
      Account: 'account',
    },
  },
};

export default function RootStack() {
    return (
      
      <NavigationContainer linking={linking} fallback={<View></View>}>

        <Drawer.Navigator
          backBehavior="history"

          drawerContent={props => {
            const {state, ...rest} = props;
            const newState = {...state};


            return (
              <DrawerContentScrollView {...props}>
                <DrawerItemList state={newState} {...rest}/>
              </DrawerContentScrollView>
            );
          }}
          initialRouteName="Home"
          screenOptions={({navigation}) => {
            return {
                drawerStyle: {
                  width: 180,
                },
                header: () => <Header navigation={navigation} title="AtLocal" />,
            }
          }}
        >

          <Drawer.Screen
            name="Home"
            component={Home}
            options={{unmountOnBlur: true}}
          />
           <Drawer.Screen
            name="About"
            component={About}
            options={{unmountOnBlur: true}}
          />
          <Drawer.Screen
            name="Listings"
            component={Listings}
            options={{unmountOnBlur: true}}
          />

          <Drawer.Screen
            name="Account"
            component={Account}
            options={{unmountOnBlur: true}}
          />

          <Drawer.Screen
            name="Listing"
            component={Listing}
            options={{drawerItemStyle: { display: 'none' }, unmountOnBlur: true}}
          />

        </Drawer.Navigator>
      </NavigationContainer>
    );
}