import React from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";


export default function FlatButton({text, onPress, icon, style, touchableStyle}) {
    return(
        <TouchableOpacity onPress={onPress} style={[touchableStyle? touchableStyle : undefined]}>
            
            <View style={[styles.buttonBackground, style? style : undefined ]}>
                {icon? icon : undefined }
                <Text style={[styles.buttonText, icon? {paddingLeft:10} : undefined ]}>{text}</Text>
            </View>
        </TouchableOpacity>
    )
    
}


const styles = StyleSheet.create({
    buttonBackground:{
        backgroundColor:"white",
        paddingVertical: 15,
        width: 250,
        borderRadius: 5,
        margin: 8,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

    },
    buttonText: {
        fontSize:18,
        textAlign:"center",
    }
})