import React from "react";
import {View, Text, StyleSheet} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";
import globalFunctions from "../modules/globalFunctions";
const tagBoxStyle = {
    padding:3,
    paddingRight: 5,
    margin: 5,
    borderRadius: 5,
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "lightgray",
    flexDirection: "row",
    alignItems: "center",
}

export default function TagView({tags, style}){
    if (tags) {
        return Object.entries(tags).map(function(tag, i){
            const tagValue = tag[1];
            const greenColor = "#48c78e";
            const redColor = "#f14668";
            const hasFeature = (<MaterialIcons name="check" size={20} color={greenColor} />)
            const doesNotHaveFeature = (<MaterialIcons name="close" size={20} color={redColor} />)
            return(
                
              <View key={i} style={tagBoxStyle}>
                {tagValue? hasFeature : doesNotHaveFeature}<Text style={{color:"black", fontSize: 20}}>{tag[0].replace("_", " ")}</Text>
              </View>
            );
          });
    }

};


